<template>
    <v-card class="mx-auto mt-16" max-width="640" outlined>
        <v-card-title class="text-h5 light-blue dark">
          Login
        </v-card-title>

        <v-card-text>
            Bitte melden Sie sich an oder registrieren Sie sich um einen Benutzer-Account einzurichten.
        </v-card-text>


        <v-card-actions>
            <v-btn color="primary" text @click="login"> Anmelden </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="register"> Registrieren </v-btn>
        </v-card-actions>
    </v-card>
</template>


<script>
//import { mapGetters } from "vuex"  // mapState


  export default {
    data () {
      return {
      }
    },

    methods : {
        login: function(event) { 
            if (event) event.preventDefault();
            this.$emit("choose-login");
        },
        register : function(event) {
            if (event) event.preventDefault();
            this.$emit("choose-registration");
        },
    },
    mounted : function() {
        if (this.$store.getters['session/get_stay_logged_in']) {
            this.$store.dispatch('auth/login', {username: this.$store.getters['session/get_username'], password: this.$store.getters['session/get_password']});
        }
        // alert(this.is_logged_in); alert(this.is_otp_required);
    },
  }
</script>

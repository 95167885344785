<template>
    <v-card class="mx-auto mt-16" max-width="640" outlined>
        <v-card-title class="text-h5 light-blue dark">
          Logout
        </v-card-title>

        <v-card-text>
            Sie sind angemeldet als {{ username }}.
        </v-card-text>

        <v-divider></v-divider>
      
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="logout()"> Abmelden </v-btn>
        </v-card-actions>
    </v-card>

</template>


<script>
import { mapGetters } from "vuex"  // mapState


  export default {
    data () {
      return {
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 4 || 'Min 4 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
      }
    },
    computed: {
        username : {
            get() {
                return this.$store.getters['session/get_username'];
            },
            set(value) {
                this.$store.dispatch('session/set_username', value);
            },
        },

        password : {
            get() {
                return this.$store.getters['session/get_password'];
            },
            set(value) {
                this.$store.dispatch('session/set_password', value);
            },
        },

        stay_logged_in : {
            get() {
                return this.$store.getters['session/get_stay_logged_in'];
            },
            set(value) {
                this.$store.dispatch('session/set_stay_logged_in', value);
            },
        }, 
        ...mapGetters("auth", ["is_logged_in", "get_user_name"]),  // , "is_otp_required"]),

    },

    methods : {
        login: function(event) { 
            if (event) event.preventDefault();
            var data = { "username" : this.username, "password" : this.password};
            this.$store.dispatch('auth/login', data);
        },
        logout : function(event) {
            if (event) event.preventDefault();
            this.$store.dispatch('session/set_stay_logged_in', false);
            this.$store.dispatch('auth/logout');
        },
    },
  }
</script>

//______________________________________________________________________________
//   |          |
//   | moenikes |           Bjoern Moenikes Software Entwicklung
//    ----------
//
//
//    essen-wie-bei-oma-Frontend
//
//    Copyright (C) by Björn Mönikes
//
//    initial author:    Björn Mönikes
//    last change:
//


// Import axios and set defaults
import axios from 'axios';
axios.defaults.withCredentials = true;

// import modules
import auth                    from './modules/auth'
import compartment             from './modules/compartment'
import freezer                 from './modules/freezer'
import frozenfood              from './modules/frozenfood'
import pdf                     from './modules/pdf'
import supply                  from './modules/supply'



class BackendAPI {
    /**
     * Instantiates the backend API
     *
     * @param  {object} options           - Option object used to initialize the BackendAPI class
     * @param  {array}  options.modules   - The included objects which are useable by the "call" function
     * @return {object}                   - The API instance
     */
    constructor(options) {
        this.modules = [];
        for (let mod in options.modules ) {
            this.modules[mod] = options.modules[mod];
            this.modules[mod].get_api_base_url = this.get_api_base_url();
        }
    }


    /**
     * Returns the base url of the frontend dependig of the current environment
     *
     * @return {string} - The base url
     */
    get_frontend_base_url() {
        if (process.env.NODE_ENV === 'development') {
            return "http://192.168.8.248:8000/";
        } else if (process.env.NODE_ENV === 'production') {
            //return "http://192.168.8.248/";
            //return "http://gefrierschrank.intranet.moenikes.net/";
            return location.protocol+"//"+location.hostname+"/";
        } else {
            alert("Undefined environment");
            return "http://127.0.0.1/";
        }
    }


    /**
     * Returns the REST API URL depending of the current environment
     *
     * @return {string} - The REST API base url
     */
    get_api_base_url() {
        if (process.env.NODE_ENV === 'development') {
            return this.get_frontend_base_url() + "";
        } else if (process.env.NODE_ENV === 'production') {
            return this.get_frontend_base_url() + "api/";
        } else {
            alert("Undefined environment");
            return "http://127.0.0.1/";
        }
    }


    /**
     * Executes an async REST API call
     *
     * @async
     * @param  {string} api_function      - Determines the function which shall be executed, format: "module/function"
     * @param  {object} params            - The params which will be commited to the function call
     * @param  {object} onSuccess         - The onSuccess callback
     * @param  {object} onFailure         - The onFailure callback
     * @return {void}                     
     */
    call(api_function, params, onSuccess, onFailure) {
        var parts = api_function.split("/");
        var module = parts[0];
        var fun = parts[1];

        this.modules[module][fun](this, params, onSuccess, onFailure);
    }
}
    


/// https://stackoverflow.com/questions/38498427/vue-js-define-a-service
/// https://vuejsdevelopers.com/2017/04/22/vue-js-libraries-plugins/


export default {
    install: function(Vue,) {
        Object.defineProperty(Vue.prototype, '$backendapi', { 
            value:  new BackendAPI( {
                modules: {
                    auth,
                    freezer,
                    compartment,
                    frozenfood,
                    pdf,
                    supply,
                }
            })
        });
    }
}

import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import Vuelidate from 'vuelidate'

import backend from '@/backend/backendapi'


import Appbar from '@/components/Appbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import ErrorSnackbar from '@/components/ErrorSnackbar.vue'

Vue.component('Appbar', Appbar);
Vue.component('Sidebar', Sidebar);
Vue.component('Footer', Footer);
Vue.component('ErrorSnackbar', ErrorSnackbar);


Vue.config.productionTip = false



//Vue.use(Vuelidate);
Vue.use(backend);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

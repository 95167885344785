//______________________________________________________________________________
//   |          |
//   | moenikes |           Bjoern Moenikes Software Entwicklung
//    ----------
//
//
//    KSM-NG-Frontend
//
//    Copyright (C) by Björn Mönikes
//
//    initial author:    Björn Mönikes
//    last change:
//

import axios from 'axios';


const auth = {
    /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {object}   data              - { "username" : "", "password" : ""}
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       - 
     */
   login(backendapi, data, onSuccess, onFailure) {
        return axios.post(backendapi.get_api_base_url() + 'auth/login', data, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },



}



export default auth

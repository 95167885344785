/// https://vuex.vuejs.org/guide/modules.html
/// https://github.com/vuejs/vuex/blob/dev/examples/shopping-cart/store/modules/cart.js

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import auth          from './modules/auth';
import errorhandling from './modules/errorhandling';
import session       from './modules/session';


Vue.use(Vuex)


const vuexPersist = new VuexPersist({
  key: 'gefrierschrank-key',
  storage: localStorage, // sessionStorage, // localStorage
  modules : ['session'],
})


export default new Vuex.Store({
    plugins: [vuexPersist.plugin],
    modules: {
        auth,
        errorhandling,
        session, 
    },
})


// https://forum.vuejs.org/t/how-to-best-write-getter-and-setters-per-vuex-store-state-value/36485/13


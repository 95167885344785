<template>
    <v-card class="mx-auto mt-16" max-width="640" outlined>
        <v-card-title class="text-h5 light-blue dark">
          Registrieren
        </v-card-title>

        <v-card-text>
            <v-form>
                <div class="overline mx-8">Ihre Daten:</div>
                <v-text-field v-model="username" 
                              :rules="[rules.required, rules.min]" 
                              label="E-Mail Adresse"></v-text-field>
                <v-text-field v-model="username" 
                              :rules="[rules.required, rules.min]" 
                              label="Vor- und Nachname oder Pseudonym"></v-text-field>
                <v-text-field v-model="password" 
                              :rules="[rules.required, rules.min]" 
                              :type="show_password ? 'text' : 'password'" 
                              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'" 
                              @click:append="show_password = !show_password" 
                              hint="At least 8 characters"
                              counter    
                              label="Password"></v-text-field>
            </v-form>
        </v-card-text>


        <v-card-actions>
            <v-btn color="primary" text @click="abort()"> Abbrechen </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="register()"> Registrieren </v-btn>
        </v-card-actions>
    </v-card>

</template>


<script>


  export default {
    data () {
      return {
        username : "",
        password : "",
        show_password: false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 4 || 'Min 4 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
      }
    },
    methods : {
        register: function(event) { 
            if (event) event.preventDefault();
            //var data = { "username" : this.username, "password" : this.password};
           
        },
        abort : function(event) {
            if (event) event.preventDefault();
            this.$emit("register-abort");
        },
    },
  }
</script>

//______________________________________________________________________________
//   |          |
//   | moenikes |           Bjoern Moenikes Software Entwicklung
//    ----------
//
//
//    KSM-NG-Frontend
//
//    Copyright (C) by Björn Mönikes
//
//    initial author:    Björn Mönikes
//    last change:
//

import axios from 'axios';


const supply = {
    /**
     * List mailboxes for given emaildomain.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {string}   emaildomain       - The emaildomain for which the list of mailboxes shall be loaded
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}
     */
    list: function(backendapi, freezer_uid, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'supply/' + freezer_uid, {}, {withCredentials: true}).then(
            response => {
                // window.console.log("supply list: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },


    /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       - 
     */
   new(backendapi, supply_obj, onSuccess, onFailure) {
        return axios.post(backendapi.get_api_base_url() + 'supply', supply_obj, {withCredentials: true}).then(
            response => {
                // window.console.log("supply new: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },

   /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       - 
     */
   update(backendapi, supply_obj, onSuccess, onFailure) {
        return axios.post(backendapi.get_api_base_url() + 'supply/'+supply_obj.uid, supply_obj, {withCredentials: true}).then(
            response => {
                // window.console.log("supply update: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },
	

   /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       - 
     */
   delete(backendapi, uid, onSuccess, onFailure) {
        return axios.delete(backendapi.get_api_base_url() + 'supply/'+ uid,{}, {withCredentials: true}).then(
            response => {
                // window.console.log("supply delete: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },




}


export default supply

<template>
    <div>
        <ChooseLoginRegistration v-if="!is_logged_in && mode == null" @choose-registration="mode=2" @choose-login="mode=1"/>
        <Login v-if="!is_logged_in && mode==1"/>
        <Logout v-if="is_logged_in"/>
        <Registration v-if="!is_logged_in && mode==2" @register-abort="mode=null"/>

        <MissingEmailConfirmationDialog v-if="false"/>
    </div>
</template>


<script>
import { mapGetters }                 from "vuex"  // mapState
import ChooseLoginRegistration        from "@/components/login/ChooseLoginRegistration"
import Login                          from "@/components/login/Login"
import Logout                         from "@/components/login/Logout"  
import MissingEmailConfirmationDialog from "@/components/login/MissingEmailConfirmationDialog"
import Registration                   from "@/components/login/Registration"


export default {
    components : {
        ChooseLoginRegistration,
        Login,
        Logout,
        MissingEmailConfirmationDialog,
        Registration,
    },
    data () {      
      return {
        mode : null,
        show_password: false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 4 || 'Min 4 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
      }
    },
    computed: {
        username : {
            get() {
                return this.$store.getters['session/get_username'];
            },
            set(value) {
                this.$store.dispatch('session/set_username', value);
            },
        },

        password : {
            get() {
                return this.$store.getters['session/get_password'];
            },
            set(value) {
                this.$store.dispatch('session/set_password', value);
            },
        },
/*
        stay_logged_in : {
            get() {
                return this.$store.getters['session/get_stay_logged_in'];
            },
            set(value) {
                this.$store.dispatch('session/set_stay_logged_in', value);
            },
        }, */
        ...mapGetters("auth", ["is_logged_in", "get_user_name"]),  // , "is_otp_required"]),

    },

    methods : {
        login: function(event) { 
            if (event) event.preventDefault();
            var data = { "username" : this.username, "password" : this.password};
            this.$store.dispatch('auth/login', data);
        },
        logout : function(event) {
            if (event) event.preventDefault();
            this.$store.dispatch('auth/logout');
        },
    },
  }
</script>

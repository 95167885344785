import router from '@/router/index.js'

const state= {
        // application state
        app: {
            error_headline: null,
            error_code: null,
            error_message: null,
        },
    }

const mutations= {
        updateError: function(state, msg) {
            if (msg.headline !== undefined)
                state.app.error_headline = msg.headline;
            else
                state.app.error_headline = null;

            if (msg.code !== undefined)
                state.app.error_code = msg.code;
            else
                state.app.error_code = null;

            if (msg.message !== undefined)
                state.app.error_message = msg.message;
            else
                state.app.error_message = null;
        },

    }

const actions= {
        setError: function(context, err) {

            console.log("setError(context, err). err:");
            console.log(err);

            let msg = { 
                "code"     : -9999,
		"headline" : "unknown - default",
		"message"  : err,
            };

            if (err.headline != undefined && (err.code != undefined || err.code == null) && err.message != undefined) {
                msg = err;
            } else if (err.response != undefined && err.response.data.error.code != undefined) {
                msg = {
                    "code"     : err.response.data.error.code,
                    "headline" : err.response.data.error.reason,
                    "message"  : err.response.data.error.description,
                };
            } else if (err.code != undefined) {
                msg = {
                    "code"     : err.code,
                    "headline" : "Success: "+err.success,
                    "message"  : err.message,
		};
		switch(err.code) {
                    case      0:    // success
                    case      1:    // WrongCredentials
                    case      2:    // AuthTokenMissing
                    case      3:    // AuthTokenInvalid
                                    break;
                    case      4:    // SessionExpired
                                    router.push('/');
                                    break;
                    case      5:    // InsertFailure
                    case      6:    // NotFound
                    case  99999:    // Generic
                    case 100000:    // NotImplemented
                                    break;
                    default:        // ?
                                    break;  
                }
            }
            context.commit('updateError', msg);
        },
        unsetError: function(context) {
            context.commit('updateError', {});
        },
    }

const getters= {
        get_error_headline: function(state) {
            return state.app.error_headline;
        },
        get_error_code: function(state) {
            return state.app.error_code;
        },
        get_error_message: function(state) {
            return state.app.error_message;
        },
    }



export default {
    state,
    getters,
    actions,
    mutations
}

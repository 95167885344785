<template>
  <v-app>
    <Appbar @hamburger-clicked="appbar_hamburger_clicked()" />
    <Sidebar ref="sidebar"/>
    <ErrorSnackbar />
    <v-main>
      <router-view/>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
    methods: {
      appbar_hamburger_clicked: function() {
         this.$refs.sidebar.drawer= ! this.$refs.sidebar.drawer;
      },
    },
};
</script>

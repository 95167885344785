<template>

<!--
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <span class="mr-2">Gefrierschrankübersicht</span>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
-->


    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
<!--      <v-btn icon large to="/">
        <v-avatar size="32px" item>
          <v-img src="https://cdn.vuetifyjs.com/images/logos/logo.svg" alt="Vuetify"></v-img></v-avatar>
      </v-btn> -->
      <v-app-bar-nav-icon @click.stop="hamburger_clicked" v-if="is_logged_in"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 400px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Gefrierschrank-Verwaltung</span>
      </v-toolbar-title>
<!--
      <v-text-field v-if="is_logged_in" flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Search" class="hidden-sm-and-down"></v-text-field>
      <v-spacer></v-spacer>
      <v-btn v-if="is_logged_in" icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn v-if="is_logged_in" icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
-->
    </v-app-bar>
</template>

<script>
  import { mapGetters } from "vuex"  // mapState

  export default {
    name: 'AppBar',
    computed: {
       ...mapGetters("auth", ["is_logged_in", "is_admin"]),
    }, 
    methods: {
        hamburger_clicked : function() {
            this.$emit("hamburger-clicked");
        },
    },
  }
</script>


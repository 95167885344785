const state = {
    username       : "",
    password       : "",
    stay_logged_in : "",
}


const getters = {
    get_username : function(state) {
        return state.username;
    },

    get_password : function(state) {
        return state.password;
    },

    get_stay_logged_in : function(state) {
        return state.stay_logged_in;
    },
}


const actions = {
    set_username : function(context, newUsername) {
        context.commit('SET_USERNAME', newUsername);
    },

    set_password : function(context, newPassword) {
        context.commit('SET_PASSWORD', newPassword);
    },

    set_stay_logged_in : function(context, newStayLoggedIn) {
        context.commit('SET_STAY_LOGGED_IN', newStayLoggedIn);
    },
}


const mutations = {
    SET_USERNAME : function(state, newUsername) {
        state.username = newUsername;
    },

    SET_PASSWORD : function(state, newPassword) {
        state.password = newPassword;
    },

    SET_STAY_LOGGED_IN : function(state, newStayLoggedIn) {
        state.stay_logged_in = newStayLoggedIn;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

<template>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app v-if="is_logged_in">
      <v-list dense>
        <template v-for="(item, idx1) in items">

          <v-divider v-if="item.divider" :key="idx1" ></v-divider>

          <v-row v-else-if="item.heading" :key="idx1" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>

          <v-list-group v-else-if="item.children" :key="idx1" v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, idx2) in item.children" :key="idx1+'-'+idx2" link :to="child.link">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="idx1" link :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from "vuex"  // mapState
//  import { mdiSpoonSugar } from '@mdi/js'; 

  export default {
    props: {
      source: String,
    },
    computed: {
       ...mapGetters("auth", ["is_logged_in", "is_admin"]),
    },
    data: () => ({
      dialog: false,
      drawer: false, //null,
      items: [
        { icon: 'mdi-snowflake', text: 'Gefrierschrankinhalt', link : "/manage-freezer" },
        { divider: true },
        { icon: 'mdi-truck-snowflake', text: 'Vorlagen', link : "/manage-supply" },
        { icon: 'mdi-tools', text: 'Gefrierschränke einrichten', link : "/setup-freezer" },
        { divider: true },
        { icon: 'mdi-cog', text: 'Einstellungen', link : "/settings" },
        { icon: 'mdi-logout', text: 'Logout', link : "/" },
/*
        { icon: 'mdi-book-open-variant', text: 'Kategorien', link : "/categories" },
        { icon: 'mdi-pot-steam', text: 'Rezepte', link : "/recipes" },
        { icon: 'mdi-silverware-spoon', text: 'Kochtechniken', link : "/cooking-techniques" },
        { icon: 'mdi-leaf', text: 'Kräuter/Gewürze', link : "/spices-herbs" },
        { divider: true },
        { icon: 'mdi-blogger', text: 'Blog', link : "/blog" },
        { icon: 'mdi-message', text: 'Kommentare', link : "/comments" },
        { divider: true },
        { icon: 'mdi-folder', text: 'Dateiverwaltung', link : "/filemanager" },
        { divider: true },
*/
/*        {
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'Account',
          model: false,
          children: [
            { icon: 'mdi-account', text: 'Account', link : "/accounts" },
            { icon: 'mdi-account-multiple', text: 'Group', link : "/groups" },
          ],
        },
*/
        // { icon: 'mdi-cog', text: 'Settings', link : "/settings" },
        // { icon: 'mdi-message', text: 'Send feedback', link : "/feedbak" },
        // { icon: 'mdi-help-circle', text: 'Help', link : "/help" },
      ],
    }),
  }
</script>


<template>
  <div class="text-center">
    <v-snackbar v-model="visible" multi-line timeout="-1">
      <b>Error {{ get_error_code }}:   <br/>
         {{ get_error_headline }} </b> <br/>
      {{ get_error_message }} <br />

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="close">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex"  // mapState


  export default {
    data: () => ({
    }),
    computed: {
        visible: function() {
          if (this.get_error_message !== null)
              return true;
          return false;
        },
        
        //...mapGetters("errorhandling", ["get_error_headline", "get_error_code", "get_error_message"]), 
        ...mapGetters( ["get_error_headline", "get_error_code", "get_error_message"]), 
    },
    mounted : function() {
        // this.$store.dispatch('setError',{"code": 1, "headline" : "Hhhhh", "message":"kkkk"});
    },
    methods : {
      close : function() {
          this.$store.dispatch('unsetError');          
      },
    },

  }
</script>

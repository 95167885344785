//______________________________________________________________________________
//   |          |
//   | moenikes |           Bjoern Moenikes Software Entwicklung
//    ----------
//
//
//    KSM-NG-Frontend
//
//    Copyright (C) by Björn Mönikes
//
//    initial author:    Björn Mönikes
//    last change:
//

import axios from 'axios';


const pdf = {
    /**
     * List mailboxes for given emaildomain.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {string}   emaildomain       - The emaildomain for which the list of mailboxes shall be loaded
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}
     */
    download: function(backendapi, freezer_uid, onSuccess, onFailure) {
/// https://www.positronx.io/vue-js-download-file-with-axios-example-tutorial/
        return axios({ 
		url: backendapi.get_api_base_url() + 'pdf/'+freezer_uid, 
		method: 'GET',
		responseType: 'blob',
		withCredentials: true}).then(
            response => {
                // window.console.log("compartment list: axios response:");
                // window.console.log(response.headers);
//                if (response.data.success == true) {
                    onSuccess(response)
//                } else {
//                    onFailure(response.data.message)
//                }
            },
            (err) => {
                onFailure(err)
            });
    },

}


export default pdf

import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store';

//import ManageFreezer from '../views/ManageFreezer.vue'
//import ManageSupply  from '../views/ManageSupply.vue'
//import SetupFreezer  from '../views/SetupFreezer.vue'
import Login         from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login",
      accessRights : {
        admin : false,
        login : false,
      },
    },	  
  },
  {
    path: '/manage-freezer',
    name: 'ManageFreezer',
    component: () => import(/* webpackChunkName: "manage-freezer" */ '../views/ManageFreezer.vue'),
    meta: {
      title: "Gefrierschrank verwalten",
      accessRights : {
        admin : false,
        login : true,
      },
    },	  
  },
  {
    path: '/manage-supply',
    name: 'ManageSupply',
    component: () => import(/* webpackChunkName: "manage-supply" */ '../views/ManageSupply.vue'),
    meta: {
      title: "Supply",
      accessRights : {
        admin : false,
        login : true,
      },
    },
  },
  {
    path: '/setup-freezer',
    name: 'SetupFreezer',
    component: () => import(/* webpackChunkName: "setup-freezer" */ '../views/SetupFreezer.vue'),
    meta: {
      title: "Gefrierschränke einrichten",
      accessRights : {
        admin : false,
        login : true,
      },
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      title: "Einstellungen",
      accessRights : {
        admin : false,
        login : true,
      },
    },
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue'),
    meta: {
      title: "Impressum",
      accessRights : {
        admin : false,
        login : false,
      },
    },
  },
  {
    path: '/data-protection',
    name: 'DataProtection',
    component: () => import(/* webpackChunkName: "data-protection" */ '../views/DataProtection.vue'),
    meta: {
      title: "Datenschutz",
      accessRights : {
        admin : false,
        login : false,
      },
    },
  },
	
  {
    path: '/delete-frozenfood-by-token/:token',
    name: 'DeleteFrozenfoodByToken',
    component: () => import(/* webpackChunkName: "delete-frozenfood-by-token" */ '../views/DeleteFrozenfoodByToken.vue'),
    meta: {
      title: "Gericht aus dem Gefrierschrank entnehmen",
      accessRights : {
        admin : false,
        login : false,
      },
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: {
      title: "404 - Seite nicht gefunden",
      accessRights : {
        admin : false,
        login : false,
      },
    },
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


const adjust_title = (to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

    next();
};


const insufficientAccessRights = (to) => { //, from, next) => {
    let admin = true;
    let login = true;
    if (to.meta && to.meta.accessRights) {
        admin = to.meta.accessRights.admin;
        login = to.meta.accessRights.login;
    }
//    window.console.log("ROUTER to "+name+" requires login: "+login+" and admin: "+admin);
//    window.console.log(from);
//    window.console.log("User is logged in: "+store.getters['auth/is_logged_in']+ " and admin: "+store.getters['auth/is_admin']);

    // return true if admin privileges are required but user isn't admin
    if (admin == true && store.getters['auth/is_admin'] != true)
        return true;
    // return true if user must be logged in but user isn't
    if (login == true && store.getters['auth/is_logged_in'] != true)
        return true;

    // verything is ok
    return false;
};



router.beforeEach((to, from, next) => {
    if (insufficientAccessRights(to,from,next)) {
        next('/');
    } else {
        adjust_title(to,from,next);
    }
})



export default router

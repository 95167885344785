//import backendapi from "../../backend/backendapi";
import router from "../../router"

const state= {
    login_user     : null,
}

const mutations= {
    LOGOUT : function(state) {
        state.login_user = null;
    },
    LOGIN : function(state, newUser) {
	console.log("LOGIN: ");
	console.log(newUser);
        state.login_user = newUser;
    },
    UPDATE_USER : function(state, newData) {
        state.login_user = newData;
    },
}


const actions= {
    login: function(context, credentials) {
        console.log(context);
        console.log(credentials);

        this._vm.$backendapi.call("auth/login", credentials,response => {
            // window.console.log(response.user);
            context.commit('LOGIN', response.user);
            router.push("/manage-freezer");
        }, (err) => {
            this.dispatch('setError',{headline: "Einloggen fehlgeschlagen.", code: null, message: err});
            console.log("store/auth");
            console.log(err);
            context.commit('LOGOUT');
        });  // add error handling: https://github.com/axios/axios/issues/960

    },

    logout: function(context) {
        context.commit('LOGOUT');
    },

    lost_password : function (context, emailaddress) {
        this._vm.$backendapi.call("user/lost_password", emailaddress, response => {
            window.console.log(response);
        },(err) => {
            window.console.log(err);
            this.dispatch('setError',{headline: "Passwort zurücksetzen fehlgeschlagen", code: null, message: err});
        });
    },
}

const getters= {
    is_logged_in: function(state) { // }, getters, rootState) {
        return ( state.login_user != null ? true : false ); 
    },

    is_admin: function(state) { //, getters, rootState) {
        return state.login_user != null && state.login_user.is_admin;
    },

    get_user : function(state) {
        return state.login_user;
    },

    get_user_id: function(state) { // , getters, rootState) {
        return state.login_user != null ? state.login_user.uid : null;
    },

    get_user_name: function(state) { // , getters, rootState) {
        return state.login_user != null ? state.login_user.realname : null;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

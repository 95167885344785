<template>


    <v-card class="mx-auto mt-16" max-width="640" outlined>
        <v-card-title class="text-h5 light-blue dark">
          Registrieren
        </v-card-title>

        <v-card-text>
            <v-form>
                <v-text-field v-model="username" label="E-Mail Adresse"></v-text-field>
                <v-text-field v-model="username" label="Vor- und Nachname oder Pseudonym"></v-text-field>
                <v-text-field v-model="password" :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show_password = !show_password" label="Password"></v-text-field>
            </v-form>
        </v-card-text>


        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="login()"> Neu registrieren </v-btn>
        </v-card-actions>
    </v-card>



</template>


<script>
import { mapGetters } from "vuex"  // mapState


  export default {
    data () {
      return {
        mode : null,
        show_password: false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 4 || 'Min 4 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
      }
    },
    computed: {
        username : {
            get() {
                return this.$store.getters['session/get_username'];
            },
            set(value) {
                this.$store.dispatch('session/set_username', value);
            },
        },

        password : {
            get() {
                return this.$store.getters['session/get_password'];
            },
            set(value) {
                this.$store.dispatch('session/set_password', value);
            },
        },
/*
        stay_logged_in : {
            get() {
                return this.$store.getters['session/get_stay_logged_in'];
            },
            set(value) {
                this.$store.dispatch('session/set_stay_logged_in', value);
            },
        }, */
        ...mapGetters("auth", ["is_logged_in", "get_user_name"]),  // , "is_otp_required"]),

    },

    methods : {
        login: function(event) { 
            if (event) event.preventDefault();
            var data = { "username" : this.username, "password" : this.password};
            this.$store.dispatch('auth/login', data);
        },
        logout : function(event) {
            if (event) event.preventDefault();
            this.$store.dispatch('auth/logout');
        },
    },
  }
</script>
